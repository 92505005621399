@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --header-height: 60px;
}
.news-content {
  background: rgba(245, 254, 125, 0.05);
}
.fs-14 {
  font-size: 14px;
}

.text-input {
  width: 100%;
  padding: 12px 20px;
  margin-top: 2px;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #5d5fef;
}

.text-input:focus {
  outline: none;
}
/* .login-page {
  height: calc(100% - var(--header-height));
  background-color: #f4f4f4;
} */

.header {
  height: var(--header-height);
  color: white;
  background: #180442;
}

.card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
}

.card-body {
  padding: 2rem;
}

.google-button {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.11);
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
}

.text-or {
  position: relative;
  height: 1.5px;
  background-color: #dedede;
  display: inline-block;
  width: 100%;
}

.text-or span {
  padding: 0 1.5rem;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.search-input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 6px;
  display: inline-block;
  box-sizing: border-box;
}

.search-input:focus {
  outline: none;
}

.bg-bar {
  background: linear-gradient(
    90deg,
    rgb(37, 37, 221) 0%,
    rgb(88, 88, 170) 50%,
    rgb(75, 53, 122) 75%,
    rgb(24, 4, 66) 100%
  );
  padding: 1rem;
  margin-top: 1.125rem;
}

.bg-bar2 {
  background: linear-gradient(
    90deg,
    rgb(24, 4, 66) 0%,
    rgb(75, 53, 122) 50%,
    rgb(88, 88, 170) 75%,
    rgb(37, 37, 221) 100%
  );
  padding: 1rem;
  margin-top: 1.125rem;
}

.label {
  font-weight: 600;
  font-size: 16px;
  color: #5d5fef;
  margin-left: 8px;
}

.error-message {
  color: #db3c30;
}
